<script setup>
import { library } from "@arrai-innovations/fontawesome-svg-core";
import { assignReactiveObject, keyDiff, useCombineClasses } from "@arrai-innovations/reactive-helpers";
import { faCircleCheck, faCircleInfo, faTimes, faTriangleExclamation } from "@arrai-innovations/sharp-solid-svg-icons";
import { FontAwesomeIcon } from "@arrai-innovations/vue-fontawesome";
import { WidgetButton } from "@ofc";
import { reactive, toRef, watch } from "vue";
import { deepUnref } from "vue-deepunref";

import DisplayDateTime from "@/components/DisplayDateTime.vue";
import ProgressBar from "@/components/ProgressBar.vue";
import { areaClasses, toastClasses, toastVariantClasses } from "@/site.theme";
import { useToasts } from "@/use/toasts";

library.add(faTriangleExclamation, faCircleInfo, faCircleCheck, faTimes);

const toasts = useToasts();
const toastSpots = ["outer", "wrapper", "inner", "prefix", "message", "time", "suffix", "progress", "fill", "content"];
const combinedToastClasses = reactive({});
for (const toastSpot of toastSpots) {
    combinedToastClasses[toastSpot] = toRef(() => toastClasses[toastSpot]);
}
const variantToastSpots = ["inner", "prefix", "message", "time", "suffix", "progress", "fill", "content"];
const errorToastClasses = reactive({});
const infoToastClasses = reactive({});
const successToastClasses = reactive({});
for (const variantToastSpot of variantToastSpots) {
    errorToastClasses[variantToastSpot] = useCombineClasses(
        toRef(() => combinedToastClasses[variantToastSpot]),
        toRef(() => toastVariantClasses.error[variantToastSpot])
    );
    infoToastClasses[variantToastSpot] = useCombineClasses(
        toRef(() => combinedToastClasses[variantToastSpot]),
        toRef(() => toastVariantClasses.info[variantToastSpot])
    );
    successToastClasses[variantToastSpot] = useCombineClasses(
        toRef(() => combinedToastClasses[variantToastSpot]),
        toRef(() => toastVariantClasses.success[variantToastSpot])
    );
}
const combinedVariantClassesByVariant = {
    error: errorToastClasses,
    info: infoToastClasses,
    success: successToastClasses,
};
const dismissProgress = reactive({});
if (import.meta.hot) {
    // don't lose dismissProgress values on hmr
    import.meta.hot.on("vite:beforeUpdate", (data) => {
        data.dismissProgress = deepUnref(dismissProgress);
    });
    import.meta.hot.on("vite:afterUpdate", (data) => {
        if ("dismissProgress" in data) {
            assignReactiveObject(dismissProgress, data.dismissProgress);
        }
    });
}
const updateDelay = 100;
watch(
    toasts.state.toasts,
    () => {
        const toastIds = toasts.state.toasts.map((t) => t.id);
        const { addedKeys, removedKeys } = keyDiff(toastIds, Object.keys(dismissProgress));
        for (let key of removedKeys) {
            clearTimeout(dismissProgress[key].timeoutId);
            delete dismissProgress[key];
        }
        for (let key of addedKeys) {
            const myToast = toasts.state.toasts.find((t) => t.id === key);
            if (!myToast.autoDismiss) {
                // push an empty object, so we don't keep trying to add this toast
                // it'll be removed when the user clicks the X
                dismissProgress[key] = {};
                continue;
            }
            const myInterval = () => {
                // close enough, setInterval is not going to be ms accurate
                if (dismissProgress[key] && dismissProgress[key].dismissTime < dismissProgress[key].maxDismiss) {
                    dismissProgress[key].dismissTime += updateDelay;
                    setTimeout(myInterval, updateDelay);
                }
            };
            dismissProgress[key] = {
                timeoutId: setTimeout(() => {
                    if (dismissProgress[key]) {
                        clearTimeout(dismissProgress[key].intervalId);
                    }
                    toasts.removeToast(key);
                }, myToast.autoDismiss),
                intervalId: setTimeout(myInterval, updateDelay),
                maxDismiss: myToast.autoDismiss,
                dismissTime: 0,
            };
        }
    },
    {
        immediate: true,
    }
);
</script>

<template>
    <div
        v-if="toasts.state.toasts.length"
        :class="[combinedToastClasses.outer, areaClasses.site.marginX, areaClasses.site.marginSmY]"
    >
        <ul :class="combinedToastClasses.wrapper" data-qa="toast">
            <li v-for="toast in toasts.state.toasts" :key="toast.id" :class="combinedToastClasses.inner">
                <div :class="combinedVariantClassesByVariant?.[toast.variant].content">
                    <div :class="combinedVariantClassesByVariant?.[toast.variant].prefix">
                        <font-awesome-icon
                            fixed-width
                            :icon="
                                { error: faTriangleExclamation, info: faCircleInfo, success: faCircleCheck }[
                                    toast.variant
                                ]
                            "
                        />
                    </div>
                    <div :class="combinedVariantClassesByVariant?.[toast.variant].message" data-qa="toast-message">
                        {{ toast.message }}
                    </div>
                    <div v-if="!toast.autoDismiss" :class="combinedVariantClassesByVariant?.[toast.variant].time">
                        <display-date-time format="relative" :value="toast.timestamp" />
                    </div>
                    <div v-if="toast.dismissible" :class="combinedVariantClassesByVariant?.[toast.variant].suffix">
                        <widget-button variant="link" @click="() => toasts.removeToast(toast.id)">X</widget-button>
                    </div>
                </div>
                <progress-bar
                    v-if="toast.autoDismiss"
                    :fill="
                        Math.floor(
                            Math.min(
                                (dismissProgress[toast.id]?.dismissTime / dismissProgress[toast.id]?.maxDismiss) * 100,
                                100
                            )
                        )
                    "
                    :fill-class="combinedVariantClassesByVariant?.[toast.variant].fill"
                    :progress-class="combinedVariantClassesByVariant?.[toast.variant].progress"
                    :use-height="true"
                />
            </li>
        </ul>
    </div>
</template>
