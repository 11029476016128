import { computed, reactive, ref } from "vue";

export const refIfDev = (obj) => {
    // only use vueRef in dev mode, themes won't change at runtime in prod
    if (import.meta.env.DEV) {
        return ref(obj);
    }
    return obj;
};
export const reactiveIfDev = (obj) => {
    // only use vueReactive in dev mode, themes won't change at runtime in prod
    if (import.meta.env.DEV) {
        return reactive(obj);
    }
    return obj;
};

export const computedIfDev = (obj) => {
    // only use vueComputed in dev mode, themes won't change at runtime in prod
    if (import.meta.env.DEV) {
        return computed(obj);
    }
    return obj();
};
