import { config } from "@arrai-innovations/fontawesome-svg-core";
import "@arrai-innovations/fontawesome-svg-core/styles.css";
import { BrowserTracing } from "@sentry/tracing";
import * as Sentry from "@sentry/vue";
import { createApp } from "vue";

import TheApp from "@/TheApp.vue";
import { register } from "@/formVariants";
import "@/index.css";
import router from "@/router";
import setupDefaultListCrud from "@/utils/listCrud.js";
import setupDefaultObjectCrud from "@/utils/objectCrud.js";

config.autoAddCss = false;

setupDefaultListCrud();
setupDefaultObjectCrud();

register();

const app = createApp(TheApp);

if (!import.meta.env.DEV) {
    // sentry interferes with error handling in setup functions, losing the stack trace
    Sentry.init({
        app,
        dsn: import.meta.env.VITE_SENTRY_DSN,
        integrations: [
            new BrowserTracing({
                routingInstrumentation: Sentry.vueRouterInstrumentation(router),
                tracePropagationTargets: ["localhost", "arrai.com", "treature.ca", /^\//],
            }),
            new Sentry.Replay({
                // Additional SDK configuration goes in here, for example:
                maskAllText: false,
                maskAllInputs: false,
            }),
        ],
        // Capture 100% of traces until we are more stable
        tracesSampleRate: 1.0,
        trackComponents: true,
        // Capture 100% of replays until we are more stable
        replaysSessionSampleRate: 1.0,
        replaysOnErrorSampleRate: 1.0,
    });
}

app.use(router);
router.isReady().then(() => {
    app.mount("#the-app");
});

export default app;
