import { assignReactiveObject } from "@arrai-innovations/reactive-helpers";
import fromPairs from "lodash-es/fromPairs.js";
import throttle from "lodash-es/throttle.js";
import { reactive, readonly } from "vue";

import { httpOrHttpsHostname } from "@/utils/connectionHostname";
import { getPermissionName } from "@/utils/crudSupport";

export class PermissionError extends Error {
    constructor(messagePrefix, response) {
        const message = `${messagePrefix}: ${response.status} ${response.statusText}`;
        super(message);
        this.name = "PermissionError";
        this.response = response;
    }
}

const internalState = reactive({
    permissions: {},
    loading: false,
    error: null,
    errored: false,
});

const fetchPerms = async () => {
    const response = await fetch(`${httpOrHttpsHostname}/routes/users/user/all_perms/`, {
        method: "GET",
        credentials: "include",
    });
    if (!response.ok) {
        // if the user is not logged in, it makes sense to have a 403 response
        if (response.status === 403) {
            assignReactiveObject(internalState.permissions, {});
            return;
        }
        throw new PermissionError("Failed to fetch permissions", response);
    }
    const responseData = await response.json();
    assignReactiveObject(internalState.permissions, fromPairs(responseData.all_perms.map((perm) => [perm, true])));
};

const throttledFetchPerms = throttle(fetchPerms, 250, { leading: true, trailing: false });

const clearPerms = () => {
    assignReactiveObject(internalState.permissions, {});
};

const hasPerm = ({ perm, app, model, action }) => {
    if (app && model && action) {
        return internalState.permissions[getPermissionName(app, model, action)];
    }
    if (perm) {
        return internalState.permissions[perm];
    }
};

export function usePermissions() {
    return {
        state: readonly(internalState),
        hasPerm,
        fetchPerms: throttledFetchPerms,
        clearPerms,
    };
}
