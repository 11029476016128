import { useResizeObserver } from "@vueuse/core";
import throttle from "lodash-es/throttle.js";
import { effectScope, watch } from "vue";

export function useFontResize({ elementRef, valueRef, minSize, desiredSize }) {
    // sizes in rem

    // observe resizing of the element
    let observer = null,
        observed = null;
    const es = effectScope();

    function pxToRem(px) {
        return px / parseFloat(getComputedStyle(document.documentElement).fontSize);
    }

    function observe() {
        // Use the maximum size in rem to fit text in the element.
        const observedElement = elementRef.value;
        if (!observedElement) {
            return;
        }
        const stepSize = pxToRem(1);
        const targetWidth = parseFloat(observedElement.clientWidth);
        let width = parseFloat(observedElement.scrollWidth);
        if (!width) {
            return desiredSize;
        }
        if (width > targetWidth) {
            let newFontSize = desiredSize;
            while (width > targetWidth && newFontSize > minSize) {
                newFontSize -= stepSize;
                observedElement.style.fontSize = `${newFontSize}rem`;
                width = parseFloat(observedElement.scrollWidth);
            }
        } else {
            // can we increase the font size?
            let newFontSize = pxToRem(parseFloat(getComputedStyle(observedElement).fontSize));
            while (width <= targetWidth && newFontSize < desiredSize) {
                newFontSize += stepSize;
                observedElement.style.fontSize = `${newFontSize}rem`;
                width = parseFloat(observedElement.scrollWidth);
            }
            if (width > targetWidth) {
                // back off one step
                newFontSize -= stepSize;
                observedElement.style.fontSize = `${newFontSize}rem`;
                width = parseFloat(observedElement.scrollWidth);
            }
        }
        if (width > targetWidth) {
            observedElement.title = observedElement.innerText;
        } else {
            observedElement.title = "";
        }
    }

    const throttledObserve = throttle(observe, 100, { leading: true, trailing: true });

    es.run(() => {
        if (elementRef.value) {
            observer = useResizeObserver(elementRef, throttledObserve);
            observed = elementRef.value;
        }

        watch(elementRef, () => {
            if (elementRef.value !== observed) {
                if (observer) {
                    observer.stop();
                }
                observed = elementRef.value;
                observer = useResizeObserver(elementRef, throttledObserve);
            }
        });

        watch(
            valueRef,
            (value) => {
                const observedElement = elementRef.value;
                if (observedElement) {
                    if (observedElement.innerText !== value) {
                        observedElement.innerText = value;
                    }
                    throttledObserve();
                }
            },
            { immediate: true }
        );
    });

    document.fonts.ready.then(() => {
        // chrome triggers fonts ready too soon, probably need to avoid web fonts with this use function
        throttledObserve();
    });

    return {
        observer,
        effectScope: es,
    };
}
