import { setObjectInstanceCrud } from "@arrai-innovations/reactive-helpers";

import { httpOrHttpsHostname } from "@/utils/connectionHostname";
import { getCSRFValue } from "@/utils/csrf";
import { FormValidationError, UnhandledResponseError } from "@/utils/errors";
import { getJsonOrText } from "@/utils/fetchSupport";

export async function defaultObjectRetrieve({ crudArgs, id, retrieveArgs }) {
    const query = retrieveArgs ? `?${new URLSearchParams(retrieveArgs).toString()}` : "";
    const controller = new AbortController();
    const returnPromise = fetch(`${httpOrHttpsHostname}/routes/${crudArgs.route}/${id}/${query}`, {
        method: "GET",
        credentials: "include",
        signal: controller.signal,
    }).then(async (response) => {
        const responseData = await getJsonOrText(response);
        if (response.status === 200) {
            return responseData;
        }
        throw new UnhandledResponseError("Failed to retrieve object", response, responseData);
    });
    returnPromise.cancel = () => controller.abort();
    return returnPromise;
}

export async function defaultObjectCreate({ crudArgs, object, retrieveArgs }) {
    const query = retrieveArgs ? `?${new URLSearchParams(retrieveArgs).toString()}` : "";
    const controller = new AbortController();
    const returnPromise = fetch(`${httpOrHttpsHostname}/routes/${crudArgs.route}/${query}`, {
        method: "POST",
        headers: {
            "X-CSRFToken": getCSRFValue(),
            "Content-Type": "application/json",
        },
        credentials: "include",
        body: JSON.stringify(object),
        signal: controller.signal,
    }).then(async (response) => {
        const responseData = await getJsonOrText(response);
        if (response.status === 201) {
            return responseData;
        }
        if (response.status === 400) {
            throw new FormValidationError(responseData, response);
        }
        throw new UnhandledResponseError("Failed to create object", response, responseData);
    });
    returnPromise.cancel = () => controller.abort();
    return returnPromise;
}

export async function defaultObjectUpdate({ crudArgs, object, retrieveArgs }) {
    const query = retrieveArgs ? `?${new URLSearchParams(retrieveArgs).toString()}` : "";
    const controller = new AbortController();
    const returnPromise = fetch(`${httpOrHttpsHostname}/routes/${crudArgs.route}/${object.id}/${query}`, {
        method: "PUT",
        headers: {
            "X-CSRFToken": getCSRFValue(),
            "Content-Type": "application/json",
        },
        credentials: "include",
        body: JSON.stringify(object),
        signal: controller.signal,
    }).then(async (response) => {
        const responseData = await getJsonOrText(response);
        if (response.status === 200) {
            return responseData;
        }
        if (response.status === 400) {
            throw new FormValidationError(responseData, response);
        }
        throw new UnhandledResponseError("Failed to update object", response, responseData);
    });
    returnPromise.cancel = () => controller.abort();
    return returnPromise;
}

export async function defaultObjectPatch({ crudArgs, id, partialObject, retrieveArgs }) {
    const query = retrieveArgs ? `?${new URLSearchParams(retrieveArgs).toString()}` : "";
    const controller = new AbortController();
    const returnPromise = fetch(`${httpOrHttpsHostname}/routes/${crudArgs.route}/${id}/${query}`, {
        method: "PATCH",
        headers: {
            "X-CSRFToken": getCSRFValue(),
            "Content-Type": "application/json",
        },
        credentials: "include",
        body: JSON.stringify(partialObject),
        signal: controller.signal,
    }).then(async (response) => {
        const responseData = await getJsonOrText(response);
        if (response.status === 200) {
            return responseData;
        }
        if (response.status === 400) {
            throw new FormValidationError(responseData, response);
        }
        throw new UnhandledResponseError("Failed to patch object", response, responseData);
    });
    returnPromise.cancel = () => controller.abort();
    return returnPromise;
}

export async function defaultObjectDelete({ crudArgs, id, deleteArgs }) {
    const abortController = new AbortController();
    const returnPromise = fetch(`${httpOrHttpsHostname}/routes/${crudArgs.route}/${id}/`, {
        method: "DELETE",
        headers: {
            "X-CSRFToken": getCSRFValue(),
        },
        credentials: "include",
        ...deleteArgs,
        signal: abortController.signal,
    }).then(async (response) => {
        if (response.status === 204) {
            return;
        }
        throw new UnhandledResponseError("Failed to delete object", response, await getJsonOrText(response));
    });
    returnPromise.cancel = () => abortController.abort();
    return returnPromise;
}

export default function setupDefaultObjectCrud() {
    setObjectInstanceCrud({
        retrieve: defaultObjectRetrieve,
        create: defaultObjectCreate,
        update: defaultObjectUpdate,
        patch: defaultObjectPatch,
        delete: defaultObjectDelete,
    });
}
