<script setup>
import cloneDeep from "lodash-es/cloneDeep.js";
import isEqual from "lodash-es/isEqual.js";
import throttle from "lodash-es/throttle.js";
import { ref, toRef, watch } from "vue";

import TheNav from "@/TheNav.vue";
import DisplayToasts from "@/components/DisplayToasts.vue";
import LoadingSpinner from "@/components/LoadingSpinner.vue";
import { areaClasses } from "@/site.theme";
import { useDarkMode } from "@/use/darkMode.js";
import { usePermissions } from "@/use/permissions";
import { useProvideLookups } from "@/use/provideLookups";
import { useUser } from "@/use/user";

const darkMode = useDarkMode();
const removeNoTransition = () => {
    document.documentElement.classList.remove("no-transition");
};

const throttledRemoveNoTransition = throttle(removeNoTransition, 150, {
    leading: false,
    trailing: true,
});

watch(darkMode.isDark, (isDark) => {
    document.documentElement.classList.add("no-transition");
    if (isDark) {
        document.documentElement.classList.add("dark");
    } else {
        document.documentElement.classList.remove("dark");
    }
    throttledRemoveNoTransition();
});
useProvideLookups();
const user = useUser();
// ### keep the body class in sync with the backgroundClasses through hmr ###
const oldList = ref();
if (import.meta.hot) {
    // don't lose oldList values on hmr
    import.meta.hot.on("vite:beforeUpdate", (data) => {
        data.oldList = cloneDeep(oldList.value);
    });
    import.meta.hot.on("vite:afterUpdate", (data) => {
        if ("oldList" in data) {
            oldList.value = data.oldList;
        }
    });
}
watch(
    [() => cloneDeep(areaClasses.content.background), () => cloneDeep(oldList.value)],
    ([newList, newOldList]) => {
        if (!isEqual(newList, newOldList)) {
            if (newOldList) {
                document.body.classList.remove(...newOldList);
            }
            oldList.value = newList;
            if (newList) {
                document.body.classList.add(...newList);
            }
        }
    },
    { immediate: true }
);
/// ### end body class hmr sync ###
const permissions = usePermissions();
permissions.fetchPerms();
watch(
    toRef(user.state, "details"),
    (newLoggedInUser, oldLoggedInUser) => {
        if (newLoggedInUser && newLoggedInUser !== oldLoggedInUser) {
            permissions.fetchPerms();
        }
    },
    { immediate: true }
);
</script>

<template>
    <div class="flex print:!text-black h-full" :class="areaClasses.textColor.regular">
        <div
            class="flex flex-col shrink-0 lg:flex-row h-full w-full"
            :class="{
                'items-center': !user.state.initialized || !user.state.loggedIn,
                'justify-center': !user.state.initialized || !user.state.loggedIn,
            }"
        >
            <the-nav v-if="user.state.initialized && user.state.loggedIn" class="shrink-0" />
            <main v-if="user.state.initialized" class="flex flex-col grow relative min-h-full justify-between">
                <router-view>
                    <template #default="{ Component, route }">
                        <keep-alive>
                            <component :is="Component" :key="route.path" />
                        </keep-alive>
                    </template>
                </router-view>
                <display-toasts />
            </main>
            <loading-spinner v-else class="!w-[10%] h-[10%]" />
        </div>
    </div>
</template>
