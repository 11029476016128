<script setup>
import { computed } from "vue";

import TagGeneric from "@/components/TagGeneric.vue";

const props = defineProps({
    fill: {
        type: Number,
        default: undefined,
    },
    progressClass: {
        type: [String, Array, Object],
        default: undefined,
    },
    fillClass: {
        type: [String, Array, Object],
        default: undefined,
    },
    useHeight: {
        type: Boolean,
        default: false,
    },
});
// todo: it would be cool to barber-pole the fill color if undefined
const fillPercent = computed(() => `${props.fill}%`);
</script>
<template>
    <tag-generic :classes="progressClass" tag="div">
        <tag-generic
            :classes="[fillClass, `progress-bar-fill-${useHeight ? 'height' : 'width'}`]"
            tag="div"
        ></tag-generic>
    </tag-generic>
</template>

<style scoped>
.progress-bar-fill-width {
    width: v-bind(fillPercent);
}
.progress-bar-fill-height {
    height: v-bind(fillPercent);
}
</style>
