import keyBy from "lodash-es/keyBy.js";
import partial from "lodash-es/partial.js";
import { createRouter, createWebHistory } from "vue-router";

import { requireAuth, requireInitialized, requirePerms, requireUnauth } from "@/router/guards";
import { makeCRUDRoutes } from "@/router/makeCrud";

const routes = [
    {
        path: "/",
        name: "home",
        component: () => import("@/views/ViewHome.vue"),
        meta: {
            navExact: true,
        },
        beforeEnter: requireInitialized,
    },
    {
        path: "/log-in/",
        name: "log-in",
        component: () => import("@/views/ViewLogIn.vue"),
        props: (route) => ({ redirect: route.query.redirect }),
        beforeEnter: requireUnauth,
    },
    {
        path: "/forgot-password/",
        name: "forgot-password",
        component: () => import("@/views/ViewForgotPassword.vue"),
        props: (route) => ({ redirect: route.query.redirect }),
        beforeEnter: requireUnauth,
    },
    {
        path: "/users/reset/:pk/",
        name: "reset-password",
        component: () => import("@/views/ViewResetPassword.vue"),
        props: (route) => {
            return {
                ...(route.params || {}),
                token: route.query.token,
                title: route.meta.title,
            };
        },
        beforeEnter: requireUnauth,
    },
    {
        path: "/log-out/",
        name: "log-out",
        component: () => import("@/views/ViewLogOut.vue"),
    },
    {
        name: "client-dispense",
        path: "/client/:client/dispense/",
        beforeEnter: [
            requireAuth,
            partial(requirePerms, ["clients.read_client", "protocols.list_allowedpurchasableamountbyclient"]),
        ],
        component: () => import("@/views/ViewDispenseClient.vue"),
        meta: {
            title: "Dispense for client",
            detail: true,
            titles: {
                view: "Dispense",
                Model: "Client",
                Models: "Clients",
            },
        },
        props: (route) => {
            return {
                ...(route.params || {}),
                ...(route.query || {}),
                title: route.meta.title,
            };
        },
    },
    ...[
        { app: "clients", model: "Animal", parentModel: "Client", views: ["list", "create", "update", "read"] },
        { app: "clients", model: "Client", views: ["list", "create", "update", "read"] },
        { app: "drugs", model: "ActiveIngredient" },
        { app: "drugs", model: "Product" },
        {
            app: "protocols",
            model: "IssuedProtocol",
            parentModel: "Client",
            views: ["list", "create", "update", "read"],
        },
        { app: "protocols", model: "Prescription", views: ["list", "read"] },
        { app: "protocols", model: "Protocol", views: ["list", "create", "update", "read"] },
        {
            app: "protocols",
            model: "IssuedProtocol",
            parentApp: "protocols",
            parentModel: "Protocol",
            views: ["list", "create", "update", "read"],
            namespace: "protocol",
            titles: {
                create: "Issue protocol",
            },
        },
        {
            app: "protocols",
            model: "IssuedProtocol",
            parentApp: "clients",
            parentModel: "Client",
            views: ["list", "create", "update", "read"],
            namespace: "client",
        },
        { app: "users", model: "User" },
        // { app: "veterinarians", model: "Clinic" },
        { app: "veterinarians", model: "Veterinarian" },
    ]
        .map(makeCRUDRoutes)
        .flat(),
    {
        path: "/:pathMatch(.*)*",
        name: "not-found",
        component: () => import("@/views/ViewNotFound.vue"),
        meta: {
            title: "Not Found",
            titles: {
                view: "Not Found",
            },
        },
        beforeEnter: requireInitialized,
        props: (route) => {
            return {
                ...(route.params || {}),
                ...(route.query || {}),
                title: route.meta.title,
            };
        },
    },
];

const routeByName = keyBy(routes, "name");

export function getRouteByName(name) {
    return routeByName[name];
}

const router = createRouter({
    history: createWebHistory(import.meta.env.BASE_URL),
    routes,
});

router.beforeEach((to, from) => {
    const toDepth = to.path.split("/").length;
    const fromDepth = from.path.split("/").length;
    to.meta.transitionName = toDepth === fromDepth ? "fade" : toDepth < fromDepth ? "slide-right" : "slide-left";
});

export default router;
