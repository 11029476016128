import { getClientPkRoutePart, getClientRoutePart } from "@/utils/crudSupport";

// custom actions tend to be detail views, so easier to define non detail views.
const NON_DETAIL_VIEWS = ["list", "create"];

export const isDetailView = (view) => !NON_DETAIL_VIEWS.includes(view);

export function getCRUDName({ parentModel, model, view }) {
    return `${parentModel ? `${getClientRoutePart(parentModel)}.` : ""}${getClientRoutePart(model)}-${view}`;
}

export function getCRUDForTo({ parentModel, model, parentPk, pk, view, throwOnUndefinedPk = false }) {
    // assumes model names are unique across apps
    const pm = getClientRoutePart(parentModel);
    const m = getClientPkRoutePart(model);
    const returnValue = {
        name: getCRUDName({ parentModel, model, view }),
        params: {},
    };
    if (pk) {
        returnValue.params[m] = pk;
    }
    if (parentPk) {
        returnValue.params[pm] = parentPk;
    }
    if (parentModel && !parentPk) {
        if (throwOnUndefinedPk) {
            throw new Error("parentPk is required when parentModel is provided");
        }
        return {};
    }
    if (model && !pk && !NON_DETAIL_VIEWS.includes(view)) {
        if (throwOnUndefinedPk) {
            throw new Error("pk is required when view is not one of " + NON_DETAIL_VIEWS.join(", "));
        }
        return {};
    }
    return returnValue;
}
