<script setup>
import { library } from "@arrai-innovations/fontawesome-svg-core";
import {
    faAnglesDown,
    faBars,
    faMoon,
    faRightFromBracket,
    faRightToBracket,
    faSuitcaseMedical,
    faSun,
} from "@arrai-innovations/sharp-solid-svg-icons";
import { FontAwesomeIcon } from "@arrai-innovations/vue-fontawesome";
import { WidgetButton, WidgetLink } from "@ofc";
import { breakpointsTailwind, useBreakpoints } from "@vueuse/core";
import { computed, onMounted, reactive, ref, toRef, watch } from "vue";
import { useRoute } from "vue-router";

import { areaClasses, errorText } from "@/site.theme";
import { useDarkMode } from "@/use/darkMode";
import { useFontResize } from "@/use/fontResize";
import { usePermissions } from "@/use/permissions";
import { useUser } from "@/use/user";
import { useVersions } from "@/use/versions";

library.add(faAnglesDown, faBars, faMoon, faRightFromBracket, faRightToBracket, faSuitcaseMedical, faSun);

const navState = reactive({
    navigationItems: [
        { name: "protocol-list", permissions: ["protocols.list_protocol"] },
        { name: "prescription-list", permissions: ["protocols.list_prescription"] },
        { name: "client-list", permissions: ["clients.list_client"] },
        {
            name: "veterinarian-list",
            permissions: ["veterinarians.list_veterinarian", "veterinarians.update_veterinarian"],
        },
        { name: "user-list", permissions: ["users.list_user", "users.update_user"] },
        { name: "product-list", permissions: ["drugs.list_product", "drugs.update_product"] },
        {
            name: "active_ingredient-list",
            permissions: ["drugs.list_activeingredient", "drugs.update_activeingredient"],
        },
        // {
        //     name: "clinic-list",
        //     permissions: ["veterinarians.list_clinic", "veterinarians.update_clinic"],
        // },
    ],
});

const user = useUser();
const permissions = usePermissions();
const darkMode = useDarkMode();
const versions = useVersions();

const hideMenu = ref(true);
const navigationPermissions = new Set();

const deployment = computed(() => {
    if (window.location.hostname.indexOf("arrai.com") !== -1) {
        return "Local";
    }
    if (window.location.hostname.startsWith("dev")) {
        return "Dev";
    }
    if (window.location.hostname.startsWith("staging")) {
        return "Staging";
    }
    if (window.location.hostname.startsWith("demo")) {
        return "Demo";
    }
    return "";
});

function toggleMenuClick() {
    hideMenu.value = !hideMenu.value;
}

function closeMenuClick(navigate) {
    return async () => {
        if (versions.newClientAvailable) {
            window.location.reload();
            return;
        }
        hideMenu.value = true;
        await navigate();
    };
}

const userNameDisplay = ref(null);
const userEmailDisplay = ref(null);

useFontResize({
    elementRef: userNameDisplay,
    valueRef: () => user.state.details.name,
    minSize: 1,
    desiredSize: 1.25,
});
useFontResize({
    elementRef: userEmailDisplay,
    valueRef: () => user.state.details.email,
    minSize: 0.75,
    desiredSize: 1,
});

for (const item of navState.navigationItems) {
    item.show = computed(() => item.permissions.every((permission) => permissions.hasPerm({ perm: permission })));
    for (const permission of item.permissions) {
        navigationPermissions.add(permission);
    }
}

const pageRoute = useRoute();

const fontsLoaded = ref(false);
document.fonts.ready.then(() => {
    fontsLoaded.value = true;
});
const breakpoints = useBreakpoints(breakpointsTailwind);
const lg = breakpoints.greaterOrEqual("lg");
const logoClasses = computed(() => {
    return [
        areaClasses.site.marginSmT,
        lg.value ? areaClasses.site.marginSmX : areaClasses.site.marginLgX,
        areaClasses.site.marginSmB,
    ];
});
watch(toRef(user.state, "loggedIn"), (value) => {
    if (value) {
        permissions.fetchPerms();
    }
});
onMounted(() => {
    if (user.state.loggedIn) {
        permissions.fetchPerms();
    }
});
</script>

<template>
    <header class="flex flex-col lg:w-[16rem] print:hidden relative z-40">
        <div
            class="flex flex-col lg:fixed lg:h-full w-[inherit] lg:border-r-4 max-lg:border-b-4"
            :class="[...areaClasses.nav.background, ...areaClasses.nav.border, ...areaClasses.nav.text]"
        >
            <div class="flex max-w-full relative max-lg:!my-0" :class="logoClasses">
                <router-link
                    class="logo-text flex-1 flex items-center justify-items-center overflow-hidden !no-underline"
                    :class="[areaClasses.nav.logoLink, { hidden: !fontsLoaded }]"
                    :to="{ name: 'home' }"
                >
                    <!--                    <img src="@/assets/treature-logo.svg" alt="Treature" />-->
                    <h1
                        class="text-center goblin-one text-2xl uppercase grow overflow-hidden leading-loose tracking-widest"
                    >
                        Treature
                    </h1>
                </router-link>
                <div class="lg:hidden py-2 flex gap-2">
                    <widget-button @click="toggleMenuClick">
                        <font-awesome-icon fixed-width :icon="faBars" />
                    </widget-button>
                </div>
            </div>
            <div
                ref="navScrollEl"
                class="flex lg:flex-1 flex-row lg:flex-col max-w-full overflow-y-auto relative"
                :class="{ 'max-lg:hidden': hideMenu }"
            >
                <nav v-if="user.state.loggedIn" class="flex flex-col lg:grow max-w-full">
                    <div
                        class="flex flex-row lg:flex-col flex-wrap lg:flex-nowrap"
                        :class="[areaClasses.site.paddingSmX]"
                    >
                        <template v-for="navigation_item in navState.navigationItems" :key="navigation_item.name">
                            <router-link
                                v-if="navigation_item.show"
                                v-slot="{ href, navigate, route }"
                                custom
                                :to="{ name: navigation_item.name }"
                            >
                                <widget-link
                                    :href="href"
                                    :input-class="[
                                        areaClasses.site.paddingSmX,
                                        areaClasses.site.paddingSmY,
                                        { 'px-3': false },
                                    ]"
                                    :navigate="closeMenuClick(navigate)"
                                    :outer-class="{ current: pageRoute.path.startsWith(route.path) }"
                                    variant="nav"
                                >
                                    {{ route.meta.title }}
                                </widget-link>
                            </router-link>
                        </template>
                    </div>
                </nav>
                <div
                    class="flex flex-col lg:w-full"
                    :class="[
                        areaClasses.site.paddingX,
                        areaClasses.site.paddingSmB,
                        areaClasses.site.gapLg,
                        areaClasses.site.marginSmT,
                    ]"
                >
                    <div>
                        <div class="flex flex-col items-left max-w-full overflow-x-hidden">
                            <template v-if="user.state.loggedIn">
                                <div
                                    ref="userNameDisplay"
                                    class="overflow-hidden whitespace-nowrap text-ellipsis text-base text-black dark:text-white"
                                    :title="user.state.details.name"
                                >
                                    {{ user.state.details.name }}
                                </div>
                                <div
                                    ref="userEmailDisplay"
                                    class="overflow-hidden whitespace-nowrap text-ellipsis text-sm text-neutral-700 dark:text-neutral-300"
                                    :title="user.state.details.email"
                                >
                                    {{ user.state.details.email }}
                                </div>
                            </template>
                        </div>
                    </div>
                    <div class="flex w-full" :class="areaClasses.site.gapSm">
                        <router-link
                            v-if="user.state.loggedIn"
                            v-slot="{ href, navigate }"
                            custom
                            :to="{ name: 'log-out' }"
                        >
                            <widget-link
                                :href="href"
                                :navigate="closeMenuClick(navigate)"
                                outer-class="min-w-max w-full"
                            >
                                Log out
                            </widget-link>
                        </router-link>
                        <div>
                            <widget-button @click="darkMode.toggle">
                                <font-awesome-icon class="inline dark:hidden" fixed-width :icon="faMoon" />
                                <font-awesome-icon class="hidden dark:inline" fixed-width :icon="faSun" />
                            </widget-button>
                        </div>
                    </div>
                    <div
                        v-if="
                            user.state.loggedIn &&
                            (user.state.details.is_superuser || user.state.details.groups.includes('Admin'))
                        "
                        class="flex flex-col text-sm w-full"
                    >
                        <div v-if="user.state.loggedInUser.is_superuser" :class="errorText">
                            Superuser: <code>True</code>
                        </div>
                        <div>
                            Site: <code>{{ deployment }}</code>
                        </div>
                        <div>
                            Server version: <code>{{ versions.serverVersion }}</code>
                        </div>
                        <div>
                            Client version: <code>{{ versions.myVersion }}</code>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </header>
</template>
