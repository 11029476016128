import { isDetailView } from "./getCrud.js";
import partial from "lodash-es/partial.js";

import { requireAuth, requirePerms } from "@/router/guards";
import {
    getCapitalizedTitle,
    getClientPkRoutePart,
    getClientRoutePart,
    getFileName,
    getLowerTitle,
    getPluralizedTitle,
} from "@/utils/crudSupport";

export function makeCRUDRoutes({
    app,
    model,
    parentApp,
    parentModel,
    fileName,
    clientRoutePart,
    lowerTitle,
    capitalizedTitle,
    pluralizedTitle,
    views = ["list", "create", "update"],
    titles,
    pkRoutePart,
}) {
    if (!titles) {
        titles = {};
    }
    let parentUrlPart = "";
    let parentRoutePart = "";
    let parentPkRoutePart = "";
    if (!app) {
        throw new Error(
            "app is required: " +
                JSON.stringify({
                    app,
                    model,
                    fileName,
                    clientRoutePart,
                    lowerTitle,
                    capitalizedTitle,
                    pluralizedTitle,
                    pkRoutePart,
                })
        );
    }
    if (!model) {
        throw new Error(
            "model is required: " +
                JSON.stringify({
                    app,
                    model,
                    fileName,
                    clientRoutePart,
                    lowerTitle,
                    capitalizedTitle,
                    pluralizedTitle,
                    pkRoutePart,
                })
        );
    }
    if (parentApp && !parentModel) {
        throw new Error(
            "parentModel is required when parentApp is provided: " +
                JSON.stringify({
                    app,
                    model,
                    fileName,
                    clientRoutePart,
                    lowerTitle,
                    capitalizedTitle,
                    pluralizedTitle,
                    pkRoutePart,
                })
        );
    }
    if (parentModel) {
        parentPkRoutePart = getClientPkRoutePart(parentModel);
        parentRoutePart = getClientRoutePart(parentModel);
        parentUrlPart = `/${parentRoutePart}/:${parentPkRoutePart}`;
        parentRoutePart = `${parentRoutePart}.`;
    }
    if (!fileName) {
        fileName = getFileName(model);
    }
    if (!clientRoutePart) {
        clientRoutePart = getClientRoutePart(model);
    }
    if (!lowerTitle) {
        lowerTitle = getLowerTitle(model);
    }
    if (!capitalizedTitle) {
        capitalizedTitle = getCapitalizedTitle(model);
    }
    if (!pluralizedTitle) {
        pluralizedTitle = getPluralizedTitle(capitalizedTitle);
    }
    if (!pkRoutePart) {
        pkRoutePart = getClientPkRoutePart(model);
    }
    app = app.toLowerCase();
    parentApp = parentApp?.toLowerCase();
    let permModelName = model.toLowerCase();
    let parentPermModelName = parentModel ? parentModel.toLowerCase() : null;
    const routes = [];
    for (let view of views) {
        const capitalizedView = view.charAt(0).toUpperCase() + view.slice(1);
        const route = {
            name: `${clientRoutePart}-${view}`,
            path: `${parentUrlPart}/${clientRoutePart}/`,
            beforeEnter: [requireAuth],
            meta: {
                title: titles[view],
                detail: isDetailView(view),
                titles: {
                    view: capitalizedView,
                    Model: capitalizedTitle,
                    Models: pluralizedTitle,
                },
            },
        };
        const permissions = [];
        if (view === "list") {
            route.meta.title = route.meta.title || pluralizedTitle;
            route.meta.breadcrumbTitle = route.meta.breadcrumbTitle || pluralizedTitle;
            route.component = () => import(`@/views/ViewList${fileName}.vue`);
            permissions.push(`${app}.list_${permModelName}`);
        } else if (view === "create") {
            route.path += "create/";
            route.meta.title = route.meta.title || `Create ${lowerTitle}`;
            route.meta.breadcrumbTitle = route.meta.breadcrumbTitle || "Create";
            route.component = () => import(`@/views/ViewCreate${fileName}.vue`);
            permissions.push(`${app}.create_${permModelName}`);
        } else if (view === "read") {
            route.path += `:${pkRoutePart}/read/`;
            route.meta.title = route.meta.title || `Read ${lowerTitle}`;
            route.meta.breadcrumbTitle = route.meta.breadcrumbTitle || "Read";
            route.component = () => import(`@/views/ViewRead${fileName}.vue`);
            permissions.push(`${app}.read_${permModelName}`);
        } else if (view === "update") {
            route.path += `:${pkRoutePart}/update/`;
            route.meta.title = route.meta.title || `Update ${lowerTitle}`;
            route.meta.breadcrumbTitle = route.meta.breadcrumbTitle || "Update";
            route.component = () => import(`@/views/ViewUpdate${fileName}.vue`);
            permissions.push(`${app}.update_${permModelName}`);
        }
        if (parentModel) {
            permissions.push(`${parentApp}.read_${parentPermModelName}`);
            route.name = `${parentRoutePart}${route.name}`;
        }
        route.props = (route) => {
            return {
                ...(route.params || {}),
                ...(route.query || {}),
                title: route.meta.title,
            };
        };
        route.beforeEnter.push(partial(requirePerms, permissions));
        routes.push(route);
    }
    if (views.some((x) => isDetailView(x))) {
        // add redirect from /model/pk/ to /model/pk/<default-view>/
        const redirectAction = views.includes("update") ? "update" : "read";
        const route = {
            name: `${parentRoutePart}${clientRoutePart}-default`,
            path: `${parentUrlPart}/${clientRoutePart}/:${pkRoutePart}/`,
            redirect: (to) => ({
                name: `${parentRoutePart}${clientRoutePart}-${redirectAction}`,
                params: to.params,
            }),
            meta: {
                title: titles[redirectAction] || capitalizedTitle,
                default: true,
                detail: true,
                redirects: `${parentRoutePart}${clientRoutePart}-${redirectAction}`,
                titles: {
                    view: redirectAction.charAt(0).toUpperCase() + redirectAction.slice(1),
                    Model: capitalizedTitle,
                    Models: pluralizedTitle,
                },
            },
        };
        routes.push(route);
    }
    return routes;
}
