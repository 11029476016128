// tailwind classes + modifiers must appear literally in source to be included in the build
import {
    clearMessageVariants,
    clearVariants,
    registerDefaultVariants,
    registerMessageVariant,
    registerVariant,
    registerWidgetSpots,
} from "@ofc";
import cloneDeep from "lodash-es/cloneDeep.js";

import { areaClasses } from "@/site.theme";

const messagesClasses = ["p-0", "m-0"];
const messageClasses = ["p-0", "m-0", "list-none"];

const labelClasses = ["font-medium"];

const interactableWidgetClasses = {
    inner: [
        "bg-white",
        "group-enabled-within:group-active:bg-neutral-200",
        "group-enabled-within:group-focus:bg-neutral-50",
        "group-enabled-within:group-focus-within:bg-neutral-50",
        "group-enabled-within:group-hover:bg-neutral-100",
        "group-enabled-within:group-hover:focus:bg-neutral-100",
        "dark:bg-material-black",
        "dark:group-enabled-within:group-active:bg-neutral-700",
        "dark:group-enabled-within:group-focus:bg-neutral-900",
        "dark:group-enabled-within:group-focus-within:bg-neutral-900",
        "dark:group-enabled-within:group-hover:bg-neutral-800",
        "dark:group-enabled-within:group-hover:focus:bg-neutral-800",
        "border-2",
        "border-neutral-300",
        "group-enabled-within:group-hover:border-neutral-600",
        "group-enabled-within:group-active:border-neutral-500",
        "group-enabled-within:group-focus:border-neutral-400",
        "group-enabled-within:group-focus-within:border-neutral-400",
        "dark:border-neutral-600",
        "dark:group-enabled-within:group-hover:border-neutral-300",
        "dark:group-enabled-within:group-active:border-neutral-400",
        "dark:group-enabled-within:group-focus:border-neutral-500",
        "dark:group-enabled-within:group-focus-within:border-neutral-500",
        "shadow-inner",
        "shadow-black/25",
    ],
};

const helpClasses = ["mt-1", "mb-2", areaClasses.textColor.muted, "text-sm"];

const buttonBaseClasses = {
    outer: ["group", "print:hidden"],
    wrapper: ["group-disabled-within:opacity-60", "group-disabled-within:cursor-pointer-none"],
    input: [
        "widget-input",
        "w-full",
        "flex",
        "items-baseline",
        "gap-1",
        "text-lg",
        "py-[0.477rem]",
        "px-3",
        "font-medium",
        "leading-snug",
    ],
    prefix: ["widget-prefix", "self-start"],
    suffix: ["widget-suffix", "self-start"],
    label: ["grow"],
    messages: [...messagesClasses],
    message: [...messageClasses],
};

const checkboxClasses = {
    spacer: ["mt-3"],
    wrapper: ["flex", "flex-row", "gap-2", "items-center", "group"],
    inner: ["relative", "pl-1", "w-8", "h-5"],
    input: [
        "absolute",
        "z-10",
        "appearance-none",
        "block",
        "w-2.5",
        "h-2.5",
        "rounded-full",
        "peer",
        "group-focus-within:outline",
        "transition-all",
        "outline-none",
        "top-[0.3125rem]",
        "left-[0.5625rem]",
        "checked:left-[1.3125rem]",
        "bg-neutral-500",
        "dark:bg-neutral-500",
        "drop-shadow",
    ],
    decorator: [
        "tag-decorator",
        "absolute",
        "z-0",
        "block",
        "rounded-full",
        "group-focus-within:outline",
        "transition-colors",
        "w-8",
        "h-5",
        "bg-white",
        "dark:bg-material-black",
        "group-enabled-within:group-hover:bg-neutral-100",
        "dark:group-enabled-within:group-hover:bg-neutral-700",
        "border-[1px]",
        "border-neutral-300",
        "dark:border-neutral-600",
        "group-enabled-within:group-hover:border-neutral-400",
        "dark:group-enabled-within:group-hover:border-neutral-500",
        "peer-checked:bg-red-500",
        "dark:peer-checked:bg-red-500",
        "group-enabled-within:group-hover:peer-checked:bg-red-600",
        "dark:group-enabled-within:group-hover:peer-checked:bg-red-600",
        // inset
        "shadow-inner",
        "shadow-black/25",
        "peer-checked:shadow-red-600",
        "dark:peer-checked:shadow-red-600",
        "group-enabled-within:group-hover:peer-checked:shadow-red-700",
        "dark:group-enabled-within:group-hover:peer-checked:shadow-red-700",
    ],
    help: [...helpClasses],
    messages: [...messagesClasses],
    message: [...messageClasses],
};

const htmlClasses = {
    outer: ["my-3", "group"],
    label: ["block", "pb-1", labelClasses],
    inner: ["group-disabled-within:opacity-60", "group-disabled-within:cursor-pointer-none"],
    editor: [
        "prose",
        "prose-gray",
        "max-w-none",
        "dark:prose-invert",
        "py-1.5",
        "m-2",
        "min-h-12",
        "text-black",
        "dark:text-white",
        "align-baseline",
        "focus:outline-none",
        "whitespace-pre-wrap",
    ],
    input: ["w-full", "px-3", "py-1.5", "align-top", "text-black", "dark:text-white"],
    help: [...helpClasses],
    messages: [...messagesClasses],
    message: [...messageClasses],
};

const inputClasses = {
    outer: ["my-3", "group"],
    label: ["block", "pb-1", labelClasses],
    input: [
        "!bg-transparent",
        "outline-none",
        "grow",
        "w-full",
        "placeholder:text-slate-500",
        "dark:placeholder:text-slate-400",
    ],
    inner: [
        "group-disabled-within:opacity-60",
        "group-disabled-within:cursor-pointer-none",
        "flex",
        "flex-row",
        "items-center",
        "gap-2",
        "h-10",
        "px-3",
        "w-full",
        "max-w-full",
        "text-black",
        "dark:text-white",
    ],
    help: [...helpClasses],
    messages: [...messagesClasses],
    message: [...messageClasses],
};

const numericInputClasses = {
    input: ["tabular-numbers", "text-right"],
};

const linkBaseClasses = {
    ...buttonBaseClasses,
    wrapper: [...buttonBaseClasses.wrapper, "group-disabled-within:pointer-events-none"],
    input: [...buttonBaseClasses.input, "no-underline"],
    label: [...buttonBaseClasses.label, "text-center"],
};

const radioClasses = {
    outer: ["my-3"],
    wrapper: ["flex", "flex-row", "gap-2", "items-center", "group"],
    label: ["flex", "flex-row", "gap-1", labelClasses],
    inner: [
        "relative",
        "pl-1",
        "w-[1.25rem]", // 20px
        "h-[1.25rem]", // 20px
        "group-disabled-within:opacity-60",
        "group-disabled-within:cursor-pointer-none",
    ],
    input: [
        "absolute",
        "z-10",
        "appearance-none",
        "block",
        "w-[0.625rem]", // 10px
        "h-[0.625rem]", // 10px
        "rounded-full",
        "peer",
        "transition-all",
        "outline-none",
        "top-[0.25rem]", // 4px
        "left-[0.25rem]", // 4px
        "checked:bg-neutral-500",
        "dark:checked:bg-neutral-500",
        "drop-shadow",
    ],
    decorator: [
        "tag-decorator",
        "absolute",
        "z-0",
        "block",
        "rounded-full",
        "group-focus-within:outline",
        "transition-colors",
        "top-0",
        "left-0",
        "w-[1.125rem]", // 18px (1px border)
        "h-[1.125rem]", // 18px (1px border)
        "bg-white",
        "dark:bg-material-black",
        "group-enabled-within:group-hover:bg-neutral-100",
        "dark:group-enabled-within:group-hover:bg-neutral-700",
        "border-[1px]",
        "border-neutral-300",
        "dark:border-neutral-600",
        "group-enabled-within:group-hover:border-neutral-400",
        "dark:group-enabled-within:group-hover:border-neutral-500",
        "peer-checked:bg-red-500",
        "dark:peer-checked:bg-red-500",
        "group-enabled-within:group-hover:peer-checked:bg-red-600",
        "dark:group-enabled-within:group-hover:peer-checked:bg-red-600",
        // inset
        "shadow-inner",
        "shadow-black/25",
        "peer-checked:shadow-red-600",
        "dark:peer-checked:shadow-red-600",
        "group-enabled-within:group-hover:peer-checked:shadow-red-700",
        "dark:group-enabled-within:group-hover:peer-checked:shadow-red-700",
    ],
    options: ["flex", "flex-col", "md:flex-row", "gap-2", "p-0", "m-0"],
    option: ["mb-1", "list-none", "p-0", "m-0"],
    help: [...helpClasses],
    messages: [...messagesClasses],
    message: [...messageClasses],
};

const readOnlyClasses = {
    outer: ["my-3", "group"],
    label: ["block", "pb-1", labelClasses],
    inner: [
        "group-disabled-within:opacity-60",
        "group-disabled-within:cursor-pointer-none",
        "w-full",
        "h-10",
        "px-3",
        "inline-block",
        "py-1.5",
        "flex",
        "gap-0.5",
        "md:gap-1",
        "lg:gap-2",
    ],
    help: [...helpClasses],
    messages: [...messagesClasses],
    message: [...messageClasses],
};

const selectClasses = {
    outer: ["my-3", "group"],
    label: ["block", "pb-1", labelClasses],
    inner: ["h-10", "flex", "flex-row", "items-center", "gap-2", "px-3", "w-full", "max-w-full"],
    input: [
        "!bg-transparent",
        "border-0",
        "group-disabled-within:opacity-60",
        "group-disabled-within:cursor-pointer-none",
        "w-full",
        "h-full",
        "outline-none",
    ],
    option: ["bg-white", "dark:bg-material-black"],
    help: [...helpClasses],
    messages: [...messagesClasses],
    message: [...messageClasses],
};

const textareaClasses = {
    outer: ["my-3", "group"],
    label: ["block", "pb-1", labelClasses],
    inner: ["group-disabled-within:opacity-60", "group-disabled-within:cursor-pointer-none"],
    input: ["!bg-transparent", "rounded-none", "outline-none", "w-full", "px-3", "py-1.5", "align-top"],
    help: [...helpClasses],
    messages: [...messagesClasses],
    message: [...messageClasses],
};

const primaryButtonClasses = {
    outer: ["drop-shadow"],
    wrapper: [
        "rounded",
        "bg-indigo-600",
        "hover:bg-indigo-700",
        "focus-visible:bg-indigo-700",
        "active:bg-indigo-800",
        "group-current:bg-indigo-800",
        "dark:bg-indigo-500",
        "dark:hover:bg-indigo-600",
        "dark:focus-visible:bg-indigo-600",
        "dark:active:bg-indigo-700",
        "dark:group-current:bg-indigo-700",
    ],
    input: ["rounded", "shadow-[inset_0_1px_0]", "shadow-white/25", "text-white"],
};
const secondaryButtonClasses = {
    outer: ["drop-shadow"],
    wrapper: [
        "rounded",
        "bg-red-700",
        "hover:bg-red-800",
        "focus-visible:bg-red-800",
        "active:bg-red-900",
        "group-current:bg-red-900",
        "dark:bg-red-500",
        "dark:hover:bg-red-600",
        "dark:focus-visible:bg-red-600",
        "dark:active:bg-red-700",
        "dark:group-current:bg-red-700",
    ],
    input: ["rounded", "shadow-[inset_0_1px_0]", "shadow-white/25", "text-neutral-100"],
};
const tertiaryButtonClasses = {
    outer: [...["drop-shadow"]],
    wrapper: [
        "rounded",
        "bg-neutral-100",
        "hover:bg-red-800",
        "focus-visible:bg-red-800",
        "active:bg-red-900",
        "group-current:bg-red-900",
        "dark:bg-neutral-800",
        "dark:hover:bg-red-500",
        "dark:focus-visible:bg-red-500",
        "dark:active:bg-red-600",
        "dark:group-current:bg-red-600",
        // border without changing the button size
        "shadow-[inset_0_0_0_2px]",
        "shadow-red-800",
        "hover:shadow-none",
        "focus-visible:shadow-none",
        "active:shadow-none",
        "group-current:shadow-none",
        "dark:shadow-red-400",
    ],
    input: [
        "rounded",
        "text-black",
        "dark:text-white",
        "hover:text-white",
        "active:text-white",
        "group-current:text-white",
        "shadow-[inset_0_1px_0]",
        "shadow-white/25",
    ],
};
const linkButtonClasses = {
    input: [
        "text-lg",
        "px-2",
        "rounded",
        "hover:bg-red-700",
        "focus-visible:bg-red-800",
        "active:bg-red-900",
        "group-current:bg-red-900",
        "text-red-800",
        "dark:text-red-400",
        "hover:text-white",
        "focus-visible:text-white",
        "active:text-white",
        "group-current:text-white",
        "dark:hover:text-white",
        "dark:focus-visible:text-white",
        "dark:active:text-white",
        "dark:group-current:text-white",
    ],
};
const smButtonClasses = {
    input: [
        {
            "text-lg": false,
            "text-sm": true,
            "px-2": true,
            "px-3": false,
        },
    ],
};
const navButtonClasses = {
    outer: ["current:drop-shadow"],
    wrapper: [
        "hover:bg-red-700",
        "focus-visible:bg-red-700",
        "active:bg-red-800",
        "group-current:bg-neutral-300",
        "dark:group-current:bg-neutral-700",
        "group-current:hover:bg-indigo-800",
        "group-current:focus-visible:bg-indigo-800",
        "group-current:active:bg-indigo-900",
        "dark:group-current:hover:bg-indigo-800",
        "dark:group-current:focus-visible:bg-indigo-800",
        "dark:group-current:active:bg-indigo-900",
        "group-current:bg-gradient-to-t",
        "group-current:lg:bg-gradient-to-r",
        "from-indigo-600",
        "from-[0.25rem]",
        "to-[0.25rem]",
        "group-current:shadow-[inset_0_1px_0]",
        "group-current:shadow-white/25",
        "flex",
        "justify-center",
    ],
    input: [
        "text-lg",
        "outline-none",
        "!font-semibold",
        "leading-[1.5rem]",
        "text-neutral-600",
        "dark:text-neutral-400",
        "group-current:text-black",
        "dark:group-current:text-white",
        "hover:!text-white",
        "active:!text-white",
    ],
};
const cellButtonClasses = {
    label: [{ "text-center": false }],
    input: [
        {
            "items-center": false,
            "text-lg": false,
        },
        "items-start",
        "px-2",
        "hover:bg-red-700",
        "focus-visible:bg-red-800",
        "active:bg-red-900",
        "group-current:bg-red-900",
        "text-red-800",
        "dark:text-red-400",
        "hover:text-white",
        "focus-visible:text-white",
        "active:text-white",
        "group-current:text-white",
        "dark:hover:text-white",
        "dark:focus-visible:text-white",
        "dark:active:text-white",
        "dark:group-current:text-white",
    ],
};
const cellNumbericButtonClasses = {
    input: ["tabular-numbers"],
};
const multiDropDownClasses = {
    tag: [
        "py-1.5",
        "px-2",
        "rounded-2xl",
        "bg-[#f06c65]",
        "flex",
        "max-h-7",
        "my-0.5",
        "mx-px",
        "flex-row",
        "whitespace-nowrap",
    ],
    tags: ["flex flex-wrap"],
    inner: [{ "flex-row": false, "h-10": false }, "min-h-[2.5rem]"],
};
const dropDownClasses = {
    outer: ["my-3", "group", "peer"],
    label: ["block", "pb-1", labelClasses],
    input: [
        "!bg-transparent",
        "outline-none",
        "grow",
        "w-full",
        "placeholder:text-slate-500",
        "dark:placeholder:text-slate-400",
    ],
    inner: [
        "disabled-within:opacity-60",
        "disabled-within:cursor-pointer-none",
        "flex",
        "flex-row",
        "items-center",
        "gap-2",
        "h-10",
        "px-3",
        "w-full",
        "max-w-full",
        "[.group.open_&]:!border-b-0",
        "[.group.open_&]:shadow-inner",
        "shadow-black/25",
    ],
    help: [...helpClasses],
    messages: [...messagesClasses],
    message: [...messageClasses],
    optionsWrapper: [
        "z-40", // should be above the nav bar
        "overflow-y-auto",
        // "p-1",
        // "pr-2",
        "bg-white",
        "peer-enabled-within:peer-active-within:!bg-neutral-200",
        "peer-enabled-within:peer-focus-within:bg-neutral-50",
        "peer-enabled-within:peer-hover-within:bg-neutral-100",
        "peer-enabled-within:peer-hover-within:peer-focus-within:bg-neutral-100",
        "dark:bg-material-black",
        "dark:peer-enabled-within:peer-active-within:!bg-neutral-700",
        "dark:peer-enabled-within:peer-focus-within:bg-neutral-900",
        "dark:peer-enabled-within:peer-hover-within:bg-neutral-800",
        "dark:peer-enabled-within:peer-hover-within:peer-focus-within:bg-neutral-800",
        "border-2",
        "border-t-0",
        "border-neutral-300",
        "peer-enabled-within:peer-hover-within:border-neutral-600",
        "peer-enabled-within:peer-active-within:!border-neutral-500",
        "peer-enabled-within:peer-focus-within:border-neutral-400",
        "dark:border-neutral-600",
        "dark:peer-enabled-within:peer-hover-within:border-neutral-300",
        "dark:peer-enabled-within:peer-active-within:!border-neutral-400",
        "dark:peer-enabled-within:peer-focus-within:border-neutral-500",
        "shadow-black/25",
    ],
    options: [
        //"pr-0.5",
        "flex",
        "flex-col",
        "gap-1",
    ],
    option: [
        "disabled:text-neutral-400",
        "dark:disabled:text-neutral-500",
        "enabled:hover:bg-neutral-200",
        "dark:enabled:hover:bg-neutral-800",
        "virtual-focused:bg-red-700",
        "virtual-focused:hover:bg-red-800",
        "virtual-focused:active:bg-red-900",
        "virtual-focused:text-white",
        "dark:virtual-focused:bg-red-500",
        "dark:virtual-focused:hover:bg-red-600",
        "dark:virtual-focused:active:bg-red-700",
        "pl-3",
        ...areaClasses.site.paddingXsR,
    ],
};

const numericDropDownClasses = {
    input: ["tabular-numbers", "text-right"],
    option: ["tabular-numbers", "text-right"],
};

const orderClasses = {
    wrapper: ["flex", "flex-row", areaClasses.site.gapSm],
    buttonOuter: ["grow"],
};

const widgetVariants = {
    button: {
        base: [buttonBaseClasses, "default"],
        primary: [primaryButtonClasses, "base"],
        smPrimary: [smButtonClasses, "primary"],
        secondary: [secondaryButtonClasses, "base"],
        default: [{}, "secondary"],
        smSecondary: [smButtonClasses, "secondary"],
        tertiary: [tertiaryButtonClasses, "base"],
        smTertiary: [smButtonClasses, "tertiary"],
        link: [linkButtonClasses, "base"],
        smLink: [smButtonClasses, "link"],
        nav: [navButtonClasses, "base"],
        cell: [cellButtonClasses, "base"],
        numeric_cell: [cellNumbericButtonClasses, "cell"],
    },
    checkbox: {
        default: [checkboxClasses, "default"],
    },
    dropDown: {
        default: [dropDownClasses, "default"],
        numeric: [numericDropDownClasses, "default"],
    },
    multiDropDown: {
        default: [multiDropDownClasses, "default"],
    },
    html: { default: [htmlClasses, "default"] },
    input: {
        default: [inputClasses, "default"],
        numeric: [numericInputClasses, "default"],
    },
    link: {
        base: [linkBaseClasses, "default"],
        primary: [primaryButtonClasses, "base"],
        smPrimary: [smButtonClasses, "primary"],
        secondary: [secondaryButtonClasses, "base"],
        smSecondary: [smButtonClasses, "secondary"],
        tertiary: [tertiaryButtonClasses, "base"],
        smTertiary: [smButtonClasses, "tertiary"],
        link: [linkButtonClasses, "base"],
        default: [{}, "link"],
        smLink: [smButtonClasses, "link"],
        nav: [navButtonClasses, "base"],
        cell: [cellButtonClasses, "base"],
        numeric_cell: [cellNumbericButtonClasses, "cell"],
    },
    radio: { default: [radioClasses, "default"] },
    readOnly: {
        default: [readOnlyClasses, "default"],
        numeric: [numericInputClasses, "default"],
    },
    select: { default: [selectClasses, "default"] },
    textarea: { default: [textareaClasses, "default"] },
    order: { default: [orderClasses, "default"] },
};
const interactableWidgetVariants = {
    html: { default: true },
    input: { default: true },
    dropDown: { default: true },
    select: { default: true },
    textarea: { default: true },
};

let registered = false;

export function register() {
    if (registered) {
        return;
    }
    registered = true;
    clearVariants();
    clearMessageVariants();
    registerDefaultVariants();
    registerCustomWidgetSpots();
    const iwcSpots = Object.keys(interactableWidgetClasses);
    const iwcLength = iwcSpots.length;
    const widgetKeys = Object.keys(widgetVariants);
    const widgetLength = widgetKeys.length;
    for (let i = 0; i < widgetLength; i++) {
        const widget = widgetKeys[i];
        const variants = Object.entries(widgetVariants[widget]);
        const variantsLength = variants.length;
        for (let j = 0; j < variantsLength; j++) {
            let [variant, classes] = variants[j];
            let base = undefined;
            if (Array.isArray(classes)) {
                base = classes[1];
                classes = classes[0];
            }
            if (interactableWidgetVariants?.[widget]?.[variant]) {
                // do not mutate the original object
                classes = cloneDeep(classes);
                for (let k = 0; k < iwcLength; k++) {
                    if (!classes[iwcSpots[k]]) {
                        classes[iwcSpots[k]] = [];
                    } else if (!Array.isArray(classes[iwcSpots[k]])) {
                        classes[iwcSpots[k]] = [classes[iwcSpots[k]]];
                    }
                    classes[iwcSpots[k]].unshift(...cloneDeep(interactableWidgetClasses[iwcSpots[k]]));
                }
            }
            registerVariant(variant, widget, classes, base);
        }
    }
    registerMessageVariant("error", {
        message: ["text-yellow-800", "dark:text-yellow-700", "font-medium"],
    });
    registerMessageVariant("success", {
        message: ["text-green-700 dark:text-green-600 font-medium"],
    });
    registerMessageVariant("info", {
        message: ["text-indigo-600 dark:text-indigo-500 font-medium"],
    });
}

const registerCustomWidgetSpots = () => {
    registerWidgetSpots("order", [
        "outer",
        "wrapper",
        "buttonOuter",
        "buttonWrapper",
        "buttonInput",
        "buttonPrefix",
        "buttonSuffix",
        "buttonLabel",
        "help",
        "messages",
        "message",
    ]);
};

if (import.meta.hot) {
    import.meta.hot.accept();
    import.meta.hot.on("vite:afterUpdate", () => {
        if (registered) {
            registered = false;
            register();
        }
    });
}
