import identity from "lodash-es/identity.js";
import isEmpty from "lodash-es/isEmpty.js";
import isUndefined from "lodash-es/isUndefined.js";

import { usePermissions } from "@/use/permissions";
import { useToasts } from "@/use/toasts";
import { useUser } from "@/use/user";

export async function waitForInitialising() {
    const user = useUser();
    if (!user.state.initialized) {
        await user.whoAmI();
    }
    return user;
}

export async function requireAuth(to /*, from*/) {
    const user = await waitForInitialising();
    if (!user.state.loggedIn) {
        return { name: "log-in", query: { redirect: to.fullPath } };
    }
}

export async function requireUnauth(/*to, from*/) {
    const user = await waitForInitialising();
    if (user.state.loggedIn) {
        return { name: "home" };
    }
}

export async function requireInitialized() {
    await waitForInitialising();
}

const createNavigationPermissionToast = {
    message: "You don't have permission to go to",
    variant: "error",
    dismissible: true,
    autoDismiss: 5000,
};

export async function requirePerms(perms, to /*, from*/) {
    const userStore = await waitForInitialising();
    const toasts = useToasts();
    const permissions = usePermissions();
    if (
        permissions.state.loading ||
        isUndefined(permissions.state.loading) ||
        (userStore.loggedIn && userStore.loggedInUser && isEmpty(permissions.state.permissions))
    ) {
        await permissions.fetchPerms();
    }
    const results = await perms.map(async (perm) => permissions.hasPerm({ perm }));
    if (!results.every(identity)) {
        toasts.addToast({
            ...createNavigationPermissionToast,
            message: `${createNavigationPermissionToast.message} "${to.name}" route.`,
        });
        return false;
    }
}
