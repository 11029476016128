<script setup>
import { combineClasses } from "@arrai-innovations/reactive-helpers";
import { computed } from "vue";

import EmptyComponent from "@/components/EmptyComponent.vue";

const props = defineProps({
    tag: {
        type: String,
        default: EmptyComponent,
    },
    // default class prop has extra logic, but we want our own logic
    classes: {
        type: [Array, String, Object],
        default: () => ({}),
    },
});

const classesComputed = computed(() => {
    return combineClasses(props.classes);
});
</script>

<template>
    <component :is="tag" :class="classesComputed">
        <slot />
    </component>
</template>
