import { tryOnMounted } from "@vueuse/core";
import once from "lodash-es/once.js";
import throttle from "lodash-es/throttle.js";
import { reactive } from "vue";

import { httpOrHttpsHostname } from "@/utils/connectionHostname";
import { getCSRFValue } from "@/utils/csrf";
import { FormValidationError } from "@/utils/errors";

class UserError extends Error {
    constructor(messagePrefix, response) {
        const message = `${messagePrefix}: ${response.status} ${response.statusText}`;
        super(message);
        this.name = "UserError";
        this.response = response;
    }
}

function checkForTypeError(error) {
    if (error instanceof TypeError) {
        // this is probably a network error
        error.response = {
            text: async () => {
                return "This is probably a network error.";
            },
        };
    }
}

const state = reactive({
    loggedIn: false,
    loggedInUser: {},
    initialized: undefined,
    loading: false,
    error: null,
    errored: false,
});

const whoAmI = async () => {
    if (state.initialized) {
        state.initialized = false;
    }
    try {
        const response = await fetch(`${httpOrHttpsHostname}/routes/users/who-am-i/`, {
            method: "GET",
            credentials: "include",
        });
        if (response.status === 200) {
            const user = await response.json();
            state.loggedIn = !!user.id;
            state.details = user;
        } else {
            // noinspection ExceptionCaughtLocallyJS
            throw new UserError("Failed to get current user", response);
        }
    } catch (error) {
        checkForTypeError(error);
        throw error;
    } finally {
        state.initialized = true;
    }
};
// even using once for init, we still need to throttle whoAmI as a promise grouping mechanism
const throttledWhoAmI = throttle(whoAmI, 250, { leading: true, trailing: false });
const init = once(throttledWhoAmI);

const login = async (payload) => {
    let response;
    try {
        response = await fetch(`${httpOrHttpsHostname}/routes/users/login/`, {
            method: "POST",
            headers: {
                "X-CSRFToken": getCSRFValue(),
                "Content-Type": "application/json",
            },
            credentials: "include",
            body: JSON.stringify(payload),
        });
        if (response.status === 204) {
            return whoAmI();
        }
        if (response.status === 400) {
            const data = await response.json();
            return Promise.reject(new FormValidationError(data));
        }
    } catch (error) {
        checkForTypeError(error);
        throw error;
    }
    throw new UserError("Failed to login", response);
};

const logout = async () => {
    if (!state.loggedIn) {
        // why call logout if you're not logged in?
        // confirm the user state
        await whoAmI();
        if (!state.loggedIn) {
            return;
        }
    }
    let response;
    try {
        response = await fetch(`${httpOrHttpsHostname}/routes/users/logout/`, {
            method: "POST",
            headers: {
                "X-CSRFToken": getCSRFValue(),
                "Content-Type": "application/json",
            },
            credentials: "include",
        });
        if (response.status === 200) {
            return whoAmI();
        }
    } catch (error) {
        checkForTypeError(error);
        throw error;
    }
    throw new UserError("Failed to logout", response);
};

export function useUser() {
    tryOnMounted(init);
    return {
        state,
        whoAmI: throttledWhoAmI,
        login,
        logout,
    };
}
