import { useLists } from "@arrai-innovations/reactive-helpers";
import { effectScope, provide } from "vue";

import { getServerRoutePart } from "@/utils/crudSupport";
import { allPagePaginatedListCrudAdaptor } from "@/utils/listCrud";

// injectors are responsible for refreshing the data
const allLookupsConfig = {
    activeIngredients: {
        props: {
            crudArgs: {
                route: getServerRoutePart("drugs", "active_ingredient"),
            },
            listArgs: {
                f: ["id", "name", "products", "current_history_id"],
                e: ["products"],
            },
            retrieveArgs: {},
        },
    },
    administrationRoutes: {
        props: {
            crudArgs: {
                route: getServerRoutePart("protocols", "administration_route"),
            },
            listArgs: {
                f: ["id", "name"],
            },
            retrieveArgs: {},
        },
    },
    amountUnits: {
        props: {
            crudArgs: {
                route: getServerRoutePart("protocols", "amount_unit"),
            },
            listArgs: {
                f: ["id", "name"],
            },
            retrieveArgs: {},
        },
    },
    bodyWeightUnits: {
        props: {
            crudArgs: {
                route: getServerRoutePart("protocols", "body_weight_unit"),
            },
            listArgs: {
                f: ["code", "name"],
            },
            retrieveArgs: {},
        },
    },
    durationUnits: {
        props: {
            crudArgs: {
                route: getServerRoutePart("protocols", "duration_unit"),
            },
            listArgs: {
                f: ["code", "name"],
            },
            retrieveArgs: {},
        },
    },
    groups: {
        props: {
            crudArgs: {
                route: getServerRoutePart("users", "group"),
            },
            listArgs: {
                f: ["id", "name"],
            },
            retrieveArgs: {},
        },
    },
    perUnits: {
        props: {
            crudArgs: {
                route: getServerRoutePart("protocols", "per_unit"),
            },
            listArgs: {
                f: ["id", "name"],
            },
            retrieveArgs: {},
        },
    },
    products: {
        props: {
            crudArgs: {
                route: getServerRoutePart("drugs", "product"),
            },
            listArgs: {
                f: ["id", "name", "manufacturer", "amount_unit", "current_history_id"],
            },
            retrieveArgs: {},
        },
    },
    protocolTypes: {
        props: {
            crudArgs: {
                route: getServerRoutePart("protocols", "protocol_type"),
            },
            listArgs: {
                f: ["id", "code", "name", "plural_name"],
            },
            retrieveArgs: {},
        },
    },
    users: {
        props: {
            crudArgs: {
                route: getServerRoutePart("users", "user"),
            },
            listArgs: {
                f: ["id", "name", "current_history_id"],
            },
            retrieveArgs: {},
        },
    },
    veterinarians: {
        props: {
            crudArgs: {
                route: getServerRoutePart("veterinarians", "veterinarian"),
            },
            listArgs: {
                f: ["id", "name_on_prescription", "user", "current_history_id"],
            },
            retrieveArgs: {},
        },
    },
};

export function useProvideLookups() {
    const es = effectScope();
    let provided = {};

    es.run(() => {
        Object.entries(allLookupsConfig).map(([key, config]) => {
            if (!config.functions) {
                config.functions = {};
            }
            if (!config.functions.list) {
                config.functions.list = allPagePaginatedListCrudAdaptor;
            }
            config.keepOldPages = true;
            return [key, config];
        });
        provided = useLists(allLookupsConfig);

        for (const [key, managedList] of Object.entries(provided)) {
            provide(`lookup_${key}`, managedList);
        }
    });

    return {
        provided,
        effectScope: es,
    };
}
