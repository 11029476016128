import camelCase from "lodash-es/camelCase.js";
import lowerCase from "lodash-es/lowerCase.js";
import snakeCase from "lodash-es/snakeCase.js";
import startCase from "lodash-es/startCase.js";
import pluralize from "pluralize";

export function getServerRoutePart(app, model) {
    // input case can be any lodash supported case
    // 'App/ModelName' -> 'app/model_name'
    return `${snakeCase(app)}/${snakeCase(model)}`;
}

export function getFileName(model) {
    // input case can be any lodash supported case
    // 'model name' -> 'ModelName'
    return `${startCase(model).replace(" ", "")}`;
}

export function getClientRoutePart(model) {
    // input case can be any lodash supported case
    // 'model name' -> 'model_name'
    return `${snakeCase(model)}`;
}

export function getLowerTitle(model) {
    // input case can be any lodash supported case
    // 'ModelName' -> 'model name'
    return `${lowerCase(model)}`;
}

export function getCapitalizedTitle(model) {
    // input case can be any lodash supported case
    // 'modelName' -> 'Model Name'
    let startCaseTitle = startCase(model);
    return startCaseTitle.charAt(0).toUpperCase() + startCaseTitle.slice(1);
}

export function getClientPkRoutePart(model) {
    // input case can be any lodash supported case
    // 'ModelName' -> 'modelName'
    // prop names in vue should be camelCase
    return `${camelCase(model)}`;
}

export function getPluralizedTitle(model) {
    // split on spaces, pluralize last word, rejoin
    let words = model.split(" ");
    words.push(pluralize(words.pop()));
    return words.join(" ");
}

export function getPermissionCase(model) {
    // input case can be any lodash supported case
    // 'ModelName' -> 'modelname'
    // 'App' -> 'app'
    return `${lowerCase(model).replace(/ /g, "")}`;
}

export function getPermissionName(app, model, action) {
    // input case can be any lodash supported case
    // 'App', 'ModelName', 'action' -> 'app.action_modelname'
    return `${getPermissionCase(app)}.${getPermissionCase(action)}_${getPermissionCase(model)}`;
}
