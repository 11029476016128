import ReconnectingWebSocket from "reconnecting-websocket";

import { connectionHostname } from "@/utils/connectionHostname";

let ws = null;
let dispatcherTarget = new EventTarget();

class DispatcherEvent extends CustomEvent {}

function setUp() {
    if (!ws) {
        ws = new ReconnectingWebSocket(
            `${window.location.protocol === "https:" ? "wss" : "ws"}://${connectionHostname}/ws/`
        );
        // ws.addEventListener("open", () => {
        //     console.debug("WebSocket opened");
        // });
        // ws.addEventListener("close", () => {
        //     console.debug("WebSocket closed");
        // });
        ws.addEventListener("error", (error) => {
            console.error("WebSocket error:", error);
        });
        ws.addEventListener("message", (message) => {
            const data = JSON.parse(message.data);
            const stream = data.stream;
            const payload = data.payload;
            if (!stream) {
                return;
            }
            const event = new DispatcherEvent(stream, { detail: payload });
            dispatcherTarget.dispatchEvent(event);
        });
    }
}

function addEventListener(stream, callback) {
    setUp();
    dispatcherTarget.addEventListener(stream, callback);
}

function removeEventListener(stream, callback) {
    dispatcherTarget.removeEventListener(stream, callback);
}

function reconnect() {
    if (ws) {
        ws.reconnect();
    } else {
        setUp();
    }
}

export default {
    addEventListener,
    removeEventListener,
    reconnect,
};
